import React, { useState, useEffect, useRef } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-router-dom";
import "./navigation-bar.css";
import logo from "../../assets/logo.gif";
import contactica from "../../assets/contactica.png";
import NavDropdown from "react-bootstrap/NavDropdown";
import Overlay from "react-bootstrap/Overlay";

export default function NavigationBar(props) {
  const [login, setLogin] = useState(false);
  const [user, setUser] = useState(null);
  const [selectTab, setSelectTab] = useState(null);
  const [show, setShow] = useState(false);
  const target = useRef(null);

  const [widthScreen, setWidthScreen] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidthScreen(window.innerWidth);
    //alert(window.innerWidth)
  }
  useEffect(() => {
    if (props) {
      setLogin(props.login);
      setUser(props.user);
    }

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [props]);

  return (
    <div>
      {widthScreen < 990 ? (
        <Navbar expand="lg" fixed="top">
          <Container>
            {login && <Navbar.Toggle aria-controls="basic-navbar-nav" />}
            <Navbar.Brand>
              <div style={{}}>
                <img
                  src={logo}
                  style={{ width: 70, height: 50 }}
                  alt="loading..."
                />
                <img
                  src={contactica}
                  style={{ width: 78, height: 13 }}
                  alt="loading..."
                />
              </div>
            </Navbar.Brand>
            {login && (
              <>
                <img
                  ref={target}
                  onClick={() => setShow(!show)}
                  style={{ width: 41, height: 41, borderRadius: 50 }}
                  src={
                    props.login && user
                      ? user.picture
                      : "https://via.placeholder.com/150/0000FF/808080"
                  }
                  alt="avatar"
                />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav
                    className="me-auto"
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "#D9D9D9",
                      borderRadius: 10,
                    }}
                  >
                    <Nav.Item
                      className={selectTab == "/dashboard/infomexico" && "nav"}
                    >
                      <Nav.Link
                        href="/dashboard/infomexico"
                        as={Link}
                        to="/dashboard/infomexico"
                        state={user}
                        onClick={() => {
                          setSelectTab("/dashboard/infomexico");
                        }}
                        eventKey="link-1"
                      >
                        INFO MÉXICO
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      className={selectTab == "/dashboard/miviaje" && "nav"}
                    >
                      <Nav.Link
                        href="/dashboard/miviaje"
                        as={Link}
                        to="/dashboard/miviaje"
                        state={user}
                        onClick={() => {
                          setSelectTab("/dashboard/miviaje");
                        }}
                        eventKey="link-1"
                      >
                        MI VIAJE
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      className={selectTab == "/dashboard/infoviaje" && "nav"}
                    >
                      <Nav.Link
                        href="/dashboard/infoviaje"
                        as={Link}
                        to="/dashboard/infoviaje"
                        state={user}
                        onClick={() => {
                          setSelectTab("/dashboard/infoviaje");
                        }}
                        eventKey="link-1"
                      >
                        INFO VIAJE
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item
                      className={selectTab == "/dashboard/intinerario" && "nav"}
                    >
                      <Nav.Link
                        href="/dashboard/intinerario"
                        as={Link}
                        to="/dashboard/intinerario"
                        state={user}
                        onClick={() => {
                          setSelectTab("/dashboard/intinerario");
                        }}
                        eventKey="link-1"
                      >
                        ITINERARIO
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Navbar.Collapse>
              </>
            )}
          </Container>
        </Navbar>
      ) : (
        <>
          <div style={{ position: "absolute", top: 0, left: 0 }}>
            <div
              className="navstick"
              style={{ width: "85%", height: 6, backgroundColor: "#E71E34" }}
            ></div>
            <div className="navlogo" style={{ marginLeft: 41, marginTop: 29 }}>
              <img
                src={logo}
                style={{ width: 70, height: 50 }}
                alt="loading..."
              />
              <img src={contactica} style={{ width: 110 }} alt="loading..." />
            </div>
          </div>
          <Navbar
            expand="lg"
            className="justify-content-end navigationTop"
            style={{ width: "100%", marginTop: 20 }}
            justify="true"
            variant="dark"
            fixed="top"
          >
            <Nav
              style={{
                paddingRight: 40,
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
              navbarScroll={false}
              defaultActiveKey="/infomexico"
            >
              {login && (
                <Nav.Item
                  style={{ marginRight: 20 }}
                  className={selectTab == "/dashboard/infomexico" && "nav"}
                >
                  <Nav.Link
                    style={{ color: "white" }}
                    href="/dashboard/infomexico"
                    as={Link}
                    to="/dashboard/infomexico"
                    state={user}
                    onClick={() => {
                      setSelectTab("/dashboard/infomexico");
                    }}
                    eventKey="link-1"
                  >
                    INFO MÉXICO
                  </Nav.Link>
                </Nav.Item>
              )}
              {login && (
                <Nav.Item
                  style={{ marginRight: 20 }}
                  className={selectTab == "/dashboard/miviaje" && "nav"}
                >
                  <Nav.Link
                    style={{ color: "white" }}
                    href="/dashboard/miviaje"
                    as={Link}
                    to="/dashboard/miviaje"
                    state={user}
                    onClick={() => {
                      setSelectTab("/dashboard/miviaje");
                    }}
                    eventKey="link-1"
                  >
                    MI VIAJE
                  </Nav.Link>
                </Nav.Item>
              )}
              {login && (
                <Nav.Item
                  style={{ marginRight: 20 }}
                  className={selectTab == "/dashboard/infoviaje" && "nav"}
                >
                  <Nav.Link
                    style={{ color: "white" }}
                    href="/dashboard/infoviaje"
                    as={Link}
                    to="/dashboard/infoviaje"
                    state={user}
                    onClick={() => {
                      setSelectTab("/dashboard/infoviaje");
                    }}
                    eventKey="link-1"
                  >
                    INFO VIAJE
                  </Nav.Link>
                </Nav.Item>
              )}
              {login && (
                <Nav.Item
                  style={{ marginRight: 20 }}
                  className={selectTab == "/dashboard/intinerario" && "nav"}
                >
                  <Nav.Link
                    style={{ color: "white" }}
                    href="/dashboard/intinerario"
                    as={Link}
                    to="/dashboard/intinerario"
                    state={user}
                    onClick={() => {
                      setSelectTab("/dashboard/intinerario");
                    }}
                    eventKey="link-1"
                  >
                    ITINERARIO
                  </Nav.Link>
                </Nav.Item>
              )}
              {login && (
                <img
                  ref={target}
                  onClick={() => setShow(!show)}
                  style={{
                    width: 41,
                    height: 41,
                    borderRadius: 50,
                    marginLeft: 40,
                  }}
                  src={
                    props.login && user
                      ? user.picture
                      : "https://via.placeholder.com/150/0000FF/808080"
                  }
                  alt="avatar"
                />
              )}
            </Nav>
          </Navbar>
        </>
      )}
      <Overlay target={target.current} show={show} placement="bottom">
        {({ placement, arrowProps, show: _show, popper, ...props }) => (
          <div
            {...props}
            style={{
              position: "absolute",
              backgroundColor: "rgba(255, 100, 100, 0.85)",
              padding: "2px 10px",
              color: "white",
              borderRadius: 3,
              zIndex: 99,
              ...props.style,
            }}
          >
            <Nav.Item className={selectTab == "/" && "nav"}>
              <Nav.Link
                href="/"
                as={Link}
                to="/"
                onClick={() => props.cerrarSesion()}
                eventKey="link-1"
                style={{ color: "white" }}
              >
                Cerrar Sesión
              </Nav.Link>
            </Nav.Item>
          </div>
        )}
      </Overlay>
    </div>
  );
}
