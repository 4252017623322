import React from "react";
import { reactLocalStorage } from "reactjs-localstorage";

import logo from "../assets/infoviaje.png";
import requisitos from "../assets/requisitos.png";
import vacunas from "../assets/vacunas.png";
import warning from "../assets/warning.png";
import buttonCartaInvitacion from "../assets/buttonCartaInvitacion.png";
import buttonReservaHotel from "../assets/buttonReservaHotel.png";
import "./dashboard.css";

import { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";

export default function MiViaje() {
  const classes = useStyles();

  const [widthScreen, setWidthScreen] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidthScreen(window.innerWidth);

    document.getElementsByClassName("content")[1].style.height = `${
      document.getElementsByClassName("content")[0].clientHeight
    }px`;

    //alert(window.innerWidth)
  }
  useEffect(() => {
    document.getElementsByClassName("content")[1].style.height = `${
      document.getElementsByClassName("content")[0].clientHeight
    }px`;
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: 40,
      }}
    >
      <div className="header infoViajeHeader">
        <div className="container">
          <div className="title">
            <h2>CONTÁCTICA TAKES CDMX</h2>
            <h3>INFORMACIÓN PREVIA AL VIAJE</h3>
          </div>
        </div>
      </div>

      <div className="container infoViaje">
        <div className="row">
          <div className="container mt-5">
            <div className="row mt-5">
              <div className="col-12 seguridad">
                <p>
                  Estas son sólo algunas recomendaciones, por favor
                  <strong>
                    {""} revisa con cuidado las especificaciones de su país
                  </strong>{" "}
                  al momento de realizar el check-in.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="row cards">
          <div className="col-md-6">
            <div className="content">
              <img
                style={{ width: 146, height: 146, marginTop: 20 }}
                src={requisitos}
                alt="Requisitos Generales"
              />
              <h3>REQUISITOS GENERALES</h3>
              <ul>
                <li>Presentar pasaporte vigente.</li>
                <li>
                  En caso de ser requerido la aerolínea te entregará para
                  diligenciar la FMM (Forma Migratoria Múltiple).
                </li>
                <li>Presentar boleto de avión de salida de México.</li>
                <li>
                  Comprobar el motivo de su viaje, mediante los siguientes
                  documentos
                </li>
              </ul>
              <img
                onClick={() =>
                  window.open(
                    "https://contacticatakescdmx.wearecontactica.com/docs/carta/" +
                      reactLocalStorage.getObject("user").email +
                      ".pdf"
                  )
                }
                src={buttonCartaInvitacion}
                alt="Logo"
              />
              <img
                onClick={() =>
                  window.open(
                    "https://contacticatakescdmx.wearecontactica.com/docs/hotel/" +
                      reactLocalStorage.getObject("user").email +
                      ".pdf"
                  )
                }
                src={buttonReservaHotel}
                alt="Logo"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="content">
              <img
                style={{ width: 146, height: 146, marginTop: 20 }}
                src={vacunas}
                alt="Vacunas"
              />
              <h3>VACUNAS</h3>
              <ul>
                <li>
                  Actualmente México no requiere vacuna contra COVID, prueba PCR
                  ni cuarentena obligatoria.
                  <br />
                  Sin embargo, te recomendamos llevar tu carnet de vacunación ya
                  que lo pueden solicitar al hacer migración.
                </li>
                <li>
                  Recuerda que durante tu viaje y estancia en los aeropuertos
                  deberás usar tapabocas en todo momento, mantener
                  distanciamiento y lavar constantemente tus manos.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid infoViajePaises p-5">
        <div className="container">
          <div>
            <h5>COLOMBIA</h5>
            <ul>
              <li>
                Desde el 1 de abril México exige para el ingreso de los
                colombianos realizar un pre-registro obligatorio:{" "}
                <a
                  href="https://www.inm.gob.mx/spublic/portal/inmex.html"
                  target="_blank"
                  className="link"
                >
                  https://www.inm.gob.mx/spublic/portal/inmex.html
                </a>
                <br />
                Para diligenciar el formulario debes tener a la mano: pasaporte,
                tiquetes de ida y regreso, reserva de hotel{" "}
                <a
                  href={
                    "https://contacticatakescdmx.wearecontactica.com/docs/hotel/" +
                    reactLocalStorage.getObject("user").email +
                    ".pdf"
                  }
                  target="_blank"
                  className="link"
                >
                  (Descargar),
                </a>{" "}
                carta de invitación{" "}
                <a
                  href={
                    "https://contacticatakescdmx.wearecontactica.com/docs/carta/" +
                    reactLocalStorage.getObject("user").email +
                    ".pdf"
                  }
                  target="_blank"
                  className="link"
                >
                  (Descargar)
                </a>{" "}
                e información detallada sobre el itinerario durante la estadía
                en México{" "}
                <a
                  href="https://contacticatakescdmx.wearecontactica.com/docs/itinerario.pdf"
                  target="_blank"
                  className="link"
                >
                  (Descargar)
                </a>
              </li>
              <li>
                Después de diligenciar el formulario recibirás en el correo
                electrónico un código QR que deberá ser presentado impreso al
                oficial de migración al momento de llegar a México.
              </li>
            </ul>
          </div>
          <div>
            <h5>ARGENTINA</h5>
            <ul>
              <li>
                Quienes no presenten esquema de vacunación completo, deberán
                hacer cuarentena y realizarse un PCR al séptimo día de ingreso,
                que de dar negativo daría por finalizado el aislamiento.
              </li>
            </ul>
          </div>
          <div>
            <h5>TODOS LOS PAÍSES</h5>
            <ul>
              <li>
                Llevar la siguiente documentación impresa y a la mano: tiquete
                de ida y regreso, reserva del hotel{" "}
                <a
                  href={
                    "https://contacticatakescdmx.wearecontactica.com/docs/hotel/" +
                    reactLocalStorage.getObject("user").email +
                    ".pdf"
                  }
                  target="_blank"
                  className="link"
                >
                  (Descargar)
                </a>
                , carta de invitación{" "}
                <a
                  href={
                    "https://contacticatakescdmx.wearecontactica.com/docs/carta/" +
                    reactLocalStorage.getObject("user").email +
                    ".pdf"
                  }
                  target="_blank"
                  className="link"
                >
                  (Descargar)
                </a>
                , itinerario{" "}
                <a
                  href="https://contacticatakescdmx.wearecontactica.com/docs/itinerario.pdf"
                  target="_blank"
                  className="link"
                >
                  (Descargar)
                </a>
                , desprendibles/comprobante de pago de los últimos 3 meses.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  cardVertical: {
    minWidth: 275,
    borderRadius: 15,
    border: `2px solid #333332`,
  },
  cardHorizontal: {
    minWidth: 275,
    borderRadius: 15,
    border: `2px solid #E71E34`,
  },
});
