import React, { useState } from "react";
import { useEffect } from "react";
import "./dashboard.css";
import Slider from "react-slick";
import { makeStyles } from "@material-ui/core";

import clima from "../assets/clima.png";
import restaurantes from "../assets/restaurantes.png";
import warning from "../assets/warning.png";

//
import Carousel from "react-grid-carousel";
import styled from "styled-components";
//

//maps
import AvenidaPaseodelaReforma from "../assets/Maps/AvenidaPaseodelaReforma.png";
import CentroHistorico from "../assets/Maps/CentroHistorico.png";
import CentroHistorico2 from "../assets/Maps/CentroHistorico2.png";
import Coyoacan from "../assets/Maps/Coyoacan.png";
import Coyoacan2 from "../assets/Maps/Coyoacan2.png";
import ElbosquedeChapultepec from "../assets/Maps/ElbosquedeChapultepec.png";
import MapaGeneral from "../assets/Maps/MapaGeneral.png";
import Polanco from "../assets/Maps/Polanco.png";
import Polanco2 from "../assets/Maps/Polanco2.png";
import RomaCondesa from "../assets/Maps/RomaCondesa.png";
import RomaCondesa2 from "../assets/Maps/RomaCondesa2.png";

//Turisticos
import Parte1 from "../assets/Turisticos/Parte1.png";
import Parte2 from "../assets/Turisticos/Parte2.png";
import Parte3 from "../assets/Turisticos/Parte3.png";

//WeWork
import A7B1 from "../assets/WeWork/A7B1.png";
import AG4 from "../assets/WeWork/AG4.png";
import varsoviaofficespace25 from "../assets/WeWork/varsoviaofficespace25.png";
import WEWORK13 from "../assets/WeWork/WEWORK13.png";
import WEWORKMAP from "../assets/WeWork/WEWORKMAP.png";
import WEWORK60 from "../assets/WeWork/WEWORK60.png";
import WEWORK126 from "../assets/WeWork/WEWORK126.png";
import WEWORK391 from "../assets/WeWork/WEWORK391.png";

//Delivery
import Didi from "../assets/Delivery/Didi.png";
import Rappi from "../assets/Delivery/Rappi.png";
import UberEats from "../assets/Delivery/UberEats.png";
import Uber from "../assets/Delivery/Uber.png";

//Slides
/* import Slider1 from "../assets/Alides/Slider1.png";
import Slider2 from "../assets/Alides/Slider2.png";
import Slider3 from "../assets/Alides/Slider3.png";
import Slider4 from "../assets/Alides/Slider4.png";
import Slider5 from "../assets/Alides/Slider5.png"; */

const Container = styled.div`
  min-height: 100%;
  width: 100%;
`;

const Row = styled.div`
  max-width: 1100px;
  padding: 0 50px;
  margin: 50px auto;
  @media screen and (max-width: 670px) {
    padding: 0;
  }
`;

const ArrowBtn = styled.span`
  display: inline-block;
  position: absolute;
  top: 50%;
  right: ${({ type }) => (type === "right" ? "-40px" : "unset")};
  left: ${({ type }) => (type === "left" ? "-40px" : "unset")};
  width: 45px;
  height: 45px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 3px 15px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  &::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: ${({ type }) =>
      type === "right"
        ? "translate(-75%, -50%) rotate(45deg)"
        : "translate(-25%, -50%) rotate(-135deg)"};
    width: 10px;
    height: 10px;
    border-top: 2px solid #666;
    border-right: 2px solid #666;
  }
  &:hover::after {
    border-color: #333;
  }
`;

const Card = styled.div`
  margin: 2px;
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
`;

const Img = styled.div`
  height: 325px;
  margin-bottom: 4px;
  background-image: ${({ img }) => `url(${img})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`;

export default function InfoMexico(props) {
  const classes = useStyles();

  const [widthScreen, setWidthScreen] = useState(window.innerWidth);

  const [selectImage, setSelectImage] = useState("/infomexico.png");
  const [selectContent, setSelectContent] = useState(0);

  function handleWindowSizeChange() {
    setWidthScreen(window.innerWidth);
    //alert(window.innerWidth)
  }
  useEffect(() => {
    if (props) {
      //alert(JSON.stringify(props))
    }
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, [props]);

  var settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ChangeContent(image, text) {
    setSelectImage(image);
    setSelectContent(text);
  }

  function DynamicContent(text) {
    switch (text) {
      case 1:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "40vh",
              marginLeft: "10vh",
            }}
            className="containerText"
          >
            <div
              style={{
                width: 50,
                height: 6,
                marginBottom: 20,
                marginLeft: 10,
                backgroundColor: "#E71E34",
              }}
            ></div>
            <h5>CONTÁCTICA TAKES</h5>
            <h1>
              PALACIO DE
              <br />
              BELLAS ARTES
            </h1>
            <h5>
              Es el principal teatro lírico y centro de expresión
              <br />
              de las bellas artes del país.
            </h5>
          </div>
        );
        break;
      case 2:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "40vh",
              marginLeft: "10vh",
            }}
            className="containerText"
          >
            <div
              style={{
                width: 50,
                height: 6,
                marginBottom: 20,
                marginLeft: 10,
                backgroundColor: "#E71E34",
              }}
            ></div>
            <h5>CONTÁCTICA TAKES</h5>
            <h1>EL ZÓCALO</h1>
            <h5>
              La Plaza de la Constitución, informalmente conocida
              <br />
              como El Zócalo, es la plaza principal de la
              <br />
              Ciudad de México
            </h5>
          </div>
        );
        break;
      case 3:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "40vh",
              marginLeft: "10vh",
            }}
            className="containerText"
          >
            <div
              style={{
                width: 50,
                height: 6,
                marginBottom: 20,
                marginLeft: 10,
                backgroundColor: "#E71E34",
              }}
            ></div>
            <h5>CONTÁCTICA TAKES</h5>
            <h1>
              MONUMENTO A<br />
              LA REVOLUCIÓN
            </h1>
            <h5>
              Es una obra arquitectónica y un mausoleo dedicado
              <br />a la conmemoración de la Revolución mexicana.
            </h5>
          </div>
        );
        break;
      case 4:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "40vh",
              marginLeft: "10vh",
            }}
            className="containerText"
          >
            <div
              style={{
                width: 50,
                height: 6,
                marginBottom: 20,
                marginLeft: 10,
                backgroundColor: "#E71E34",
              }}
            ></div>
            <h5>CONTÁCTICA TAKES</h5>
            <h1>
              PIRAMIDES DE
              <br />
              TEOTIHUACÁN
            </h1>
            <h5>
              La palabra Teotihuacán traduce “lugar donde
              <br />
              los hombres se convierten en dioses”.
            </h5>
          </div>
        );
        break;
      case 5:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "40vh",
              marginLeft: "10vh",
            }}
            className="containerText"
          >
            <div
              style={{
                width: 50,
                height: 6,
                marginBottom: 20,
                marginLeft: 10,
                backgroundColor: "#E71E34",
              }}
            ></div>
            <h5>CONTÁCTICA TAKES</h5>
            <h1>
              CASTILLO DE <br />
              CHAPULTEPEC
            </h1>
            <h5>
              Fue el hogar de los virreyes españoles y el Emperador
              <br />
              Maximiliano de Habsburgo. Posteriormente fue
              <br />
              también Casa Presidencial hasta 1940
            </h5>
          </div>
        );
        break;
      default:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: "40vh",
              marginLeft: "10vh",
            }}
            className="containerText"
          >
            <div
              style={{
                width: 50,
                height: 6,
                marginBottom: 20,
                marginLeft: 10,
                backgroundColor: "#E71E34",
              }}
            ></div>
            <h5>CONTÁCTICA TAKES</h5>
            <h1>
              CIUDAD <br />
              DE MÉXICO
            </h1>
          </div>
        );
        break;
    }
  }

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        flexDirection: "column",
        paddingBottom: 40,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundImage: `url(${selectImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          height: "100vh",
        }}
      >
        {DynamicContent(selectContent)}
        <div style={{ display: "flex", flex: 1, marginTop: "40vh" }}>
          {/* <Container>
            <Row>
              <Carousel
                interval={null}
                cols={4}
                rows={1}
                gap={11}
                responsiveLayout={[
                  {
                    breakpoint: 1200,
                    cols: 3,
                  },
                  {
                    breakpoint: 990,
                    cols: 2,
                  },
                ]}
                mobileBreakpoint={670}
                arrowRight={<ArrowBtn type="right" />}
                arrowLeft={<ArrowBtn type="left" />}
              >
                <Carousel.Item>
                  <Card>
                    <Img
                      onClick={() => ChangeContent("/Background1.png", 1)}
                      img={"/Slider1.png"}
                    />
                  </Card>
                </Carousel.Item>
                <Carousel.Item>
                  <Card>
                    <Img
                      onClick={() => ChangeContent(`/Background2.png`, 2)}
                      img={"/Slider2.png"}
                    />
                  </Card>
                </Carousel.Item>
                <Carousel.Item>
                  <Card>
                    <Img
                      onClick={() => ChangeContent(`/Background3.png`, 3)}
                      img={"/Slider3.png"}
                    />
                  </Card>
                </Carousel.Item>
                <Carousel.Item>
                  <Card>
                    <Img
                      onClick={() => ChangeContent(`/Background4.png`, 4)}
                      img={"/Slider4.png"}
                    />
                  </Card>
                </Carousel.Item>
                <Carousel.Item>
                  <Card>
                    <Img
                      onClick={() => ChangeContent(`/Background5.png`, 5)}
                      img={"/Slider5.png"}
                    />
                  </Card>
                </Carousel.Item>
              </Carousel>
            </Row>
          </Container> */}
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="climaMexico col-md-12 mb-md-5 mt-md-5">
            <img
              src={clima}
              alt="Clima en México"
              style={{ display: widthScreen < 990 ? "none" : "block" }}
            />
            <div className={widthScreen < 990 ? "mobile" : null}>
              <span>¿CÓMO ES EL CLIMA EN MÉXICO EN OCTUBRE?</span>
              <p>
                En el mes de octubre estamos en pleno otoño. No hace mucho frío,
                pero es airoso y hay días de lluvia. Recomendamos que para su
                vestimenta traigan elementos como:
              </p>
              <ul>
                <li>Pantalones</li>
                <li>Suéters</li>
                <li>Chamarras ligeras</li>
                <li>Zapatos Cerrados</li>
                <li>Gafas de sol</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container lugaresCaminar pb-4">
        <div className="row">
          <div className="col-md-12">
            <h2>LUGARES PARA CAMINAR EN CDMX</h2>
            <div>
              <Slider {...settings}>
                <div>
                  <img src={AvenidaPaseodelaReforma} alt="Logo" />
                  <p>Avenida Paseo de la Reforma</p>
                </div>
                <div>
                  <img src={CentroHistorico} alt="Logo" />
                  <p>Centro Histórico</p>
                </div>
                <div>
                  <img src={CentroHistorico2} alt="Logo" />
                  <p>Centro Histórico</p>
                </div>
                <div>
                  <img src={Coyoacan} alt="Logo" />
                  <p>Coyoacán</p>
                </div>
                <div>
                  <img src={Coyoacan2} alt="Logo" />
                  <p>Coyoacán</p>
                </div>
                <div>
                  <img src={ElbosquedeChapultepec} alt="Logo" />
                  <p>El Bosque de Chapultepec</p>
                </div>
                <div>
                  <img src={MapaGeneral} alt="Logo" />
                  <p>Mapa General</p>
                </div>
                <div>
                  <img src={Polanco} alt="Logo" />
                  <p>Polanco</p>
                </div>
                <div>
                  <img src={Polanco2} alt="Logo" />
                  <p>Polanco</p>
                </div>
                <div>
                  <img src={RomaCondesa} alt="Logo" />
                  <p>Roma Condesa</p>
                </div>
                <div>
                  <img src={RomaCondesa2} alt="Logo" />
                  <p>Roma Condesa</p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid lugaresTuristicos py-5">
        <div className="container ">
          <div className="row">
            <div className="col-md-12">
              <h2 className="mb-5 text-center">LUGARES TURÍSTICOS</h2>
            </div>
            <div className="col-md-12">
              <Slider {...settings}>
                <div>
                  <img src={Parte1} alt="Logo" />
                </div>
                <div>
                  <img src={Parte2} alt="Logo" />
                </div>
                <div>
                  <img src={Parte3} alt="Logo" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>

      <div className="container wework pt-5 pb-2">
        <div className="row">
          <div className="col-md-12">
            <h2>WEWORK CERCANO</h2>
          </div>
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-7">
                <Slider {...settings}>
                  <img src={WEWORK126} alt="Logo" />
                  <img src={WEWORKMAP} alt="Logo" />
                  <img src={A7B1} alt="Logo" />
                  <img src={AG4} alt="Logo" />
                  <img src={varsoviaofficespace25} alt="Logo" />
                  <img src={WEWORK13} alt="Logo" />
                  <img src={WEWORK60} alt="Logo" />
                  <img src={WEWORK391} alt="Logo" />
                </Slider>
              </div>
              <div className="col-md-5 mt-md-0 mt-4">
                <h3>WEWORK VARSOVIA</h3>
                <div className="iconLocation">
                  <strong>
                    Varsovia 36, Colonia Juárez, <br />
                    Ciudad de México 06600
                  </strong>
                </div>
                <span>TRANSPORTES CERCANOS</span>
                <div className="iconMetro">
                  La estación Sevilla del metro está a 3 cuadras
                </div>
                <div className="iconCar">
                  Estacionamiento dentro del edificio
                  <br />
                  <strong>(MEX$1,700)</strong>
                </div>
                <div className="iconMap">
                  A dos cuadras del Ángel de la Independencia, sobre el Paseo de
                  la Reforma
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid mt-md-3 infoRestaurantes">
        <img width="100%" src={restaurantes} alt="Logo" />
        <div className="container">
          <div
            className="px-md-5 py-4 row"
            style={{ maxWidth: 800, margin: "auto" }}
          >
            <div className="col-sm-4">
              <ul>
                <li>
                  <a href="">Restaurante Balta</a>{" "}
                </li>
                <li>
                  <a href="">Restaurante Rossetta </a>
                </li>
                <li>
                  <a href="">Balcón del Zócalo</a>
                </li>
                <li>
                  <a href="">La Vicenta</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-4">
              <ul>
                <li>
                  <a href="">La Casa de Toño</a>{" "}
                </li>
                <li>
                  <a href="">Cantina La 20</a>{" "}
                </li>
                <li>
                  <a href="">TACOS</a>
                </li>
                <li>
                  <a href="">El Califa</a>
                </li>
              </ul>
            </div>
            <div className="col-sm-4">
              <ul>
                <li>
                  <a href="">Taquearte</a>{" "}
                </li>
                <li>
                  <a href="">Tizoncito</a>{" "}
                </li>
                <li>
                  <a href="">El Farolito</a>
                </li>
                <li>
                  <a href="">El Moro</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="apps container my-4">
        <h2>APPS DELIVERY Y TRANSPORTE</h2>
        <div className="justify-content-center row">
          <div className="col-12 col-xl-10 logos mt-5">
            <img src={Didi} alt="Logo" className="col-md-3 col-6 mb-5" />
            <img src={Rappi} alt="Logo" className="col-md-3 col-6 mb-5" />
            <img src={UberEats} alt="Logo" className="col-md-3 col-6 mb-5" />
            <img src={Uber} alt="Logo" className="col-md-3 col-6 mb-5" />
          </div>
        </div>
      </div>

      <div className="container-fluid mexicanoCercano mt-md-3 pt-5">
        <div className="container">
          <h2>TU MEXICANO MÁS CERCANO</h2>
          <div className="px-5 py-4 row">
            <div className="col-md-4">
              <ul>
                <li>
                  Ricardo Valdovinos <br />
                  <a target="_blank" href="https://wa.me/+525537128267">
                    (+52) 5537128267
                  </a>
                </li>
                <li>
                  Fernanda Castro
                  <br />
                  <a target="_blank" href="https://wa.me/+527772531107 ">
                    (+52) 7772531107{" "}
                  </a>
                </li>
                <li>
                  Juan Esteban Montoya <br />
                  <a target="_blank" href="https://wa.me/+573117500170">
                    (+57) 3117500170
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul>
                <li>
                  Carla A. Rodríguez <br />
                  <a target="_blank" href="https://wa.me/+5215543506106">
                    (+52) 1554350-6106
                  </a>
                </li>
                <li>
                  Pablo Román Corzo
                  <br />
                  <a target="_blank" href="https://wa.me/+525579401364 ">
                    (+52) 5579401364{" "}
                  </a>
                </li>
                <li>
                  Renata Villarreal <br />
                  <a target="_blank" href="https://wa.me/+525625983800">
                    (+52) 56 2598 3800
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <ul>
                <li>
                  Noé Gracida <br />
                  <a target="_blank" href="https://wa.me/+525513669510">
                    (+52) 55 1366 9510
                  </a>
                </li>
                <li>
                  Jimena Bernal
                  <br />
                  <a target="_blank" href="https://wa.me/+526691200876 ">
                    (+52) 669 120 0876{" "}
                  </a>
                </li>
                <li>
                  Lilith Takahashi <br />
                  <a target="_blank" href="https://wa.me/+525583354263">
                    (+52) 55 8335 4263
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container mt-5">
        <div className="row mt-5">
          <div className="col-12 seguridad">
            <h2>CONSEJOS DE SEGURIDAD</h2>
            <div className="row" style={{}}>
              <div className="col-md-6 gx-md-5">
                <ul>
                  <li>No saques tu celular en el transporte público.</li>
                  <li>Procura no sacar tu celular en la calle.</li>
                  <li>No salgas de noche solx</li>
                  <li>
                    Si viajas en Uber procura viajar acompañadx o en su defecto,
                    compartir tu ubicación con alguien.
                  </li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul>
                  <li>Utiliza cubrebocas en el transporte público. </li>
                  <li>Está prohibido tomar alcohol en la calle/banqueta.</li>
                  <li>
                    Si sales de antro/discoteca/rumba cuida tu trago y ponle la
                    mano encima.
                  </li>
                  <li>No vayas a Tepito, Neza</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const useStyles = makeStyles({
  cardHorizontal: {
    minWidth: 275,
    borderRadius: 30,
    border: `2px solid #E71E34`,
  },
});
