import React from "react";
import itinerario from "../assets/itinerario.png";
import "./dashboard.css";
import { useEffect } from "react";

export default function Intinerario() {
  useEffect(() => {}, []);

  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignContent: "center",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: 40,
      }}
    >
      <div className="header itinerarioHeader">
        <div className="container">
          <div className="title">
            <h2>CONTÁCTICA TAKES CDMX</h2>
            <h3>ITINERARIO</h3>
          </div>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flex: 1,
          flexDirection: "column",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flex: 1,
            width: "70%",
            flexDirection: "column",
            marginTop: 20,
          }}
        >
          <div style={{ fontWeight: "bold", color: "#E71E34" }}>
            MIÉRCOLES 19
          </div>
          <div
            style={{ width: "100%", height: 2, backgroundColor: "#E71E34" }}
          ></div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1 }}></div>
              <div style={{ display: "flex", flex: 1 }}>Llegada de vuelos</div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                8:30 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Cena con todo el equipo
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            width: "70%",
            flexDirection: "column",
            marginTop: 60,
          }}
        >
          <div style={{ fontWeight: "bold", color: "#E71E34" }}>JUEVES 20</div>
          <div
            style={{ width: "100%", height: 2, backgroundColor: "#E71E34" }}
          ></div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                7:00 AM - 12:00
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Desayuno en el hotel
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                9:00 AM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Salida al Castillo de Chapultepec
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                9:30 AM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Llegada a Chapultepec
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                10:00 AM - 12:00 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Visita a Castillo de Chapultepec
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                12:00 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>Traslado al Centro</div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                12:30 PM - 2:00 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Paseo por el centro: Zócalo y Catedral
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                2:00 PM - 4:00 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Almuerzo Equipo Contáctica
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                4:00 PM - 5:00 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Paseo por el centro: Bellas Artes
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                5:00 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>Regreso hotel</div>
            </div>

            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                5:30 PM{" "}
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Preparación Contactiween
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                8:30 PM{" "}
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Salida a Contactiween
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                9:00 PM - 3:00 AM{" "}
              </div>
              <div style={{ display: "flex", flex: 1 }}>Contactiween</div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            width: "70%",
            flexDirection: "column",
            marginTop: 60,
          }}
        >
          <div style={{ fontWeight: "bold", color: "#E71E34" }}>VIERNES 21</div>
          <div
            style={{ width: "100%", height: 2, backgroundColor: "#E71E34" }}
          ></div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                7:00 AM - 12:00
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Desayuno en el hotel
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                10:00 AM{" "}
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Salida a Teotihuacan
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                11:00 AM - 1:00 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Recorrido Teotihuacan
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                1:00 PM - 2:00 PM{" "}
              </div>
              <div style={{ display: "flex", flex: 1 }}>Almuerzo</div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                2:30 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>Regreso a WeWork</div>
            </div>

            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                4:00 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>Llegada al WeWork</div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                4:00 PM - 6:00 PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>Sesión por equipos</div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                6:00PM
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                A partir de esta hora noche libre
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            flex: 1,
            width: "70%",
            flexDirection: "column",
            marginTop: 60,
          }}
        >
          <div style={{ fontWeight: "bold", color: "#E71E34" }}>SABADO 22</div>
          <div
            style={{ width: "100%", height: 2, backgroundColor: "#E71E34" }}
          ></div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div style={{ display: "flex", flex: 1, fontWeight: "bold" }}>
                7:00 AM - 12:00
              </div>
              <div style={{ display: "flex", flex: 1 }}>
                Desayuno en el hotel
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                flex: 1,
                flexDirection: "row",
                marginTop: 10,
                marginBottom: 10,
              }}
            >
              <div
                style={{ display: "flex", flex: 1, fontWeight: "bold" }}
              ></div>
              <div style={{ display: "flex", flex: 1 }}>
                Regreso país origen
              </div>
            </div>
            <div
              style={{ width: "100%", height: 1, backgroundColor: "#C4C4C4" }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
}
